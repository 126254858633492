/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hamburger': {
    width: 64,
    height: 64,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M408 442h480a8 8 0 008-8v-56a8 8 0 00-8-8H408a8 8 0 00-8 8v56a8 8 0 008 8zm-8 204a8 8 0 008 8h480a8 8 0 008-8v-56a8 8 0 00-8-8H408a8 8 0 00-8 8v56zm504-486H120a8 8 0 00-8 8v56a8 8 0 008 8h784a8 8 0 008-8v-56a8 8 0 00-8-8zm0 632H120a8 8 0 00-8 8v56a8 8 0 008 8h784a8 8 0 008-8v-56a8 8 0 00-8-8zM142.4 642.1L298.7 519a8.8 8.8 0 000-13.9L142.4 381.9a8.9 8.9 0 00-14.4 6.9v246.3a8.9 8.9 0 0014.4 7z"/>'
  }
})
